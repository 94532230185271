import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"

import router from '@/router'



// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init() {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config
      // if (status === 401) {
      if (response && response.status === 401) {
        // this.$vs.loading.close()

        // if (response.config.url.includes('refresh')){
        //   router.push('/pages/login')
        //   localStorage.removeItem("access_token")
        //   localStorage.removeItem("refresh_token")
        //   return
        // }
        if (!isAlreadyFetchingAccessToken) {
          // console.log(response)

          isAlreadyFetchingAccessToken = true
          store.dispatch("auth/fetchAccessToken")
            .then((access_token) => {
              onAccessTokenFetched(access_token)
            })
        }else {
          localStorage.clear()
          router.push('/pages/login')
          isAlreadyFetchingAccessToken = false
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login(phone, code) {
    return axios.post("/auth/verify-code/", {phone, code}).catch(() => this.loading.close())
  },
  refreshToken() {
    return axios.post("/auth/refresh-token/",{refresh_token: localStorage.getItem("refresh_token")})
  }
}
