/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/instagram/user/izoofficialmusic",
          rule: "editor",
        },

        {
          path: "/instagram/user/:username",
          name: "graph",
          component: () => import("@/views/pages/instagram/user.vue"),
          meta: {
            breadcrumb: [{ title: "Graph", active: true }],
            pageTitle: "Home",
            rule: "editor",
          },
        },
        {
          path: "/instagram/word_cloud/:username",
          name: "graph",
          component: () => import("@/views/pages/instagram/word_cloud.vue"),
          meta: {
            breadcrumb: [{ title: "Graph", active: true }],
            pageTitle: "Home",
            rule: "editor",
          },
        },
        {
          path: "/instagram/follower/:username",
          name: "graph",
          component: () => import("@/views/pages/graph.vue"),
          meta: {
            breadcrumb: [{ title: "Graph", active: true }],
            pageTitle: "Home",
            rule: "editor",
          },
        },
        {
          path: "/instagram/post/:username",
          name: "graph",
          component: () => import("@/views/pages/instagram/post.vue"),
          meta: {
            breadcrumb: [{ title: "Graph", active: true }],
            rule: "editor",
          },
        },
        {
          path: "/instagram/twitter/:username",
          name: "graph",
          component: () => import("@/views/pages/instagram/twitter.vue"),
          meta: {
            breadcrumb: [{ title: "Graph", active: true }],
            rule: "editor",
          },
        },
        {
          path: "/instagram/loyalty/:username",
          name: "graph",
          component: () => import("@/views/pages/graph.vue"),
          meta: {
            breadcrumb: [{ title: "Graph", active: true }],
            pageTitle: "Home",
            rule: "editor",
          },
        },
        // {
        //   path: "/graph",
        //   name: "graph",
        //   component: () => import("@/views/pages/graph.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "Graph", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/izoOfficial/post",
        //   name: "post",
        //   component: () => import("@/views/pages/izoOfficial/Post.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "post", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/izoOfficial/charts",
        //   name: "post",
        //   component: () => import("@/views/pages/izoOfficial/charts.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "post", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/izoOfficial/bubblecart",
        //   name: "post",
        //   component: () => import("@/views/pages/izoOfficial/bubbleChart.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "post", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/iyla/post",
        //   name: "post",
        //   component: () => import("@/views/pages/iyla/Post.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "post", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {

        // {
        //   path: "/iyla/overview",
        //   name: "overview",
        //   component: () => import("@/views/pages/iyla/overview.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "post", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/iyla/charts",
        //   name: "post",
        //   component: () => import("@/views/pages/iyla/charts.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "post", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/graph2",
        //   name: "graph2",
        //   component: () => import("@/views/pages/iyla.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "Graph", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/wordCloudIyla",
        //   name: "graph3",
        //   component: () => import("@/views/pages/iyla/wordClod.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "Graph", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },
        // {
        //   path: "/wordCloudIzo",
        //   name: "graph4",
        //   component: () => import("@/views/pages/izoOfficial/wordCloud2.vue"),
        //   meta: {
        //     breadcrumb: [{ title: "Graph", active: true }],
        //     pageTitle: "Home",
        //     rule: "editor",
        //   },
        // },

        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
          path: "",
          component: () => import("@/layouts/full-page/FullPage.vue"),
          children: [
            // =============================================================================
            // PAGES
            // =============================================================================
            {
              path: "/callback",
              name: "auth-callback",
              component: () => import("@/views/Callback.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/login",
              name: "page-login",
              component: () => import("@/views/pages/login/Login.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/register",
              name: "page-register",
              component: () => import("@/views/pages/register/Register.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/forgot-password",
              name: "page-forgot-password",
              component: () => import("@/views/pages/ForgotPassword.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/lock-screen",
              name: "page-lock-screen",
              component: () => import("@/views/pages/LockScreen.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/error-404",
              name: "page-error-404",
              component: () => import("@/views/pages/Error404.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/error-500",
              name: "page-error-500",
              component: () => import("@/views/pages/Error500.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/not-authorized",
              name: "page-not-authorized",
              component: () => import("@/views/pages/NotAuthorized.vue"),
              meta: {
                rule: "editor",
              },
            },
            {
              path: "/pages/maintenance",
              name: "page-maintenance",
              component: () => import("@/views/pages/Maintenance.vue"),
              meta: {
                rule: "editor",
              },
            },
          ],
        },
        // Redirect to 404 page, if no match found
        // {
        //   path: "*",
        //   redirect: "/pages/error-404",
        // },
      ],
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
